import { useReducer, useEffect, useState, useRef } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { useRouter } from "next/router";

// Track URLs that have returned 401 errors to prevent retrying them
const failedAuthUrls = new Set<string>();

const fetcher = async (url: string, token: string | null) => {
  if (!token) {
    throw new Error("Token is not available");
  }

  // Check if this URL has already failed with a 401 error
  if (failedAuthUrls.has(url)) {
    const error = new Error(`Failed to get api sourced options: {"error":"Request previously failed with 401 Unauthorized"}`);
    (error as any).isAuthError = true;
    throw error;
  }

  let newUrl = process.env.NEXT_PUBLIC_CUSTOMER_API_HOST + url;

  try {
    const res = await fetch(newUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Special handling for unauthorized errors to prevent retries
    if (res.status === 401) {
      // Add URL to the failed list to prevent future retries
      failedAuthUrls.add(url);
      
      const errorData = await res.json().catch(() => ({ error: "Unauthorized" }));
      const error = new Error(`Failed to get api sourced options: ${JSON.stringify(errorData)}`);
      // Add a property to the error to indicate it's an auth error
      (error as any).isAuthError = true;
      throw error;
    }

    if (!res.ok) {
      const errorData = await res.json().catch(() => ({ error: `${res.status} ${res.statusText}` }));
      throw new Error(errorData.error || `Failed to fetch data: ${res.status}`);
    }

    const data = await res.json();
    return data;
  } catch (error) {
    // Rethrow authentication errors without modification to preserve the isAuthError flag
    if ((error as any).isAuthError) {
      throw error;
    }
    
    // For other errors, provide more context
    throw new Error(`Failed to get api sourced options: ${JSON.stringify({ error: (error as Error).message || String(error) })}`);
  }
};

const fetchToken = async () => {
  try {
    const res = await fetch("/api/token", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      const currentPath = window.location.pathname + window.location.search;
      window.location.href = `/api/auth/login?returnTo=${encodeURIComponent(currentPath)}`;
      throw new Error(`Error fetching access token: ${res.statusText}`);
    }

    const data = await res.json();
    localStorage.setItem("token", data.accessToken); // Store the token in localStorage
    return data.accessToken;
  } catch (error) {
    const currentPath = window.location.pathname + window.location.search;
    window.location.href = `/api/auth/login?returnTo=${encodeURIComponent(currentPath)}`;
    console.error("Error fetching access token", error);
    throw error;
  }
};

// Define the reducer
const tokenReducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return action.token;
    default:
      return state;
  }
};

const isTokenExpired = (token) => {
  try {
    const payloadBase64 = token.split(".")[1];
    const decodedJson = atob(payloadBase64);
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp * 1000; // Convert to milliseconds
    return Date.now() > exp;
  } catch (error) {
    console.error("Failed to decode token", error);
    return true; // Assume expired if there's an error decoding
  }
};

const defaultTransformer = (data: any) => data?.result || data;

interface UseApiOptions {
  transformer?: (data: any) => any;
  immutable?: boolean;
}

const useApi = (
  url: string,
  condition?: boolean,
  options: UseApiOptions = {}
) => {
  const router = useRouter();
  const [token, dispatch] = useReducer(tokenReducer, null);
  const [apiError, setApiError] = useState<Error | null>(null);
  const { immutable = false, transformer = defaultTransformer } = options;
  const prevUrlRef = useRef<string | null>(null);

  useEffect(() => {
    const getToken = async () => {
      try {
        let accessToken = localStorage.getItem("token");
        if (accessToken && isTokenExpired(accessToken)) {
          accessToken = null; // Clear the expired token
        }
        if (!accessToken) {
          accessToken = await fetchToken(); // Fetch a new token if it's not in localStorage or if expired
          localStorage.setItem("token", accessToken ? accessToken : ""); // Store the new token
        }
        if (isTokenExpired(accessToken)) {
          localStorage.removeItem("token");
          accessToken = null;
          router.push("/api/auth/login");
        }

        if (!accessToken) {
          throw new Error("Token is not available");
        }

        dispatch({ type: "SET_TOKEN", token: accessToken });
      } catch (error) {
        console.error(error);
        setApiError(error as Error);
      }
    };

    getToken();
  }, [url]);

  // Reset the URL tracking if the URL changes
  useEffect(() => {
    if (url !== prevUrlRef.current) {
      prevUrlRef.current = url;
    }
  }, [url]);

  // Skip the SWR call if the URL has already failed with a 401
  const shouldFetch = condition && token && url && !failedAuthUrls.has(url);

  const swrHook = immutable ? useSWRImmutable : useSWR;

  const {
    data: uncleanedData,
    error,
    mutate,
    isLoading,
  } = swrHook(
    shouldFetch ? url : null, 
    (url) => fetcher(url, token),
    {
      // Add SWR options to prevent retries on 401 errors
      shouldRetryOnError: (error) => {
        // Don't retry on authentication errors
        const isAuthError = (error as any).isAuthError;
        if (isAuthError && url) {
          // Ensure we track this URL as failed
          failedAuthUrls.add(url);
        }
        return !isAuthError;
      },
      // Limit retry attempts for other errors
      errorRetryCount: 2,
      errorRetryInterval: 3000, // 3 seconds between retries
      dedupingInterval: 10000, // 10 seconds deduping to avoid hammering the API
      revalidateOnFocus: false, // Don't revalidate on focus for api-source calls
    }
  );
  
  const data = uncleanedData !== undefined && uncleanedData !== null ? transformer(uncleanedData) : uncleanedData;
  const apiErrorMessage = error ? error.message : null;

  return { data, isLoading, mutate, error, apiError, apiErrorMessage };
};

export default useApi;
