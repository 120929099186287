import { FontAwesomeIcon } from "helpers/helpers";
import React, { useState, useEffect, useRef } from "react";

interface DropdownProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
  label: string;
  disabled?: boolean;
  errorMessage?: string; // New prop for error message
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  value,
  onChange,
  label,
  disabled,
  errorMessage, // Destructure the new prop
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shouldRenderAbove, setShouldRenderAbove] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  // const calculateDropdownPosition = () => {
  //   if (dropdownRef.current) {
  //     const rect = dropdownRef.current.getBoundingClientRect();
  //     const spaceBelow = window.innerHeight - rect.bottom;
  //     const spaceAbove = rect.top;

  //     // Set the dropdown to render above if there's more space above than below
  //     setShouldRenderAbove(spaceAbove > spaceBelow);
  //   }
  // };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    // window.addEventListener("resize", calculateDropdownPosition);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      // window.removeEventListener("resize", calculateDropdownPosition);
    };
  }, []);

  // useEffect(() => {
  //   if (isOpen) {
  //     calculateDropdownPosition();
  //   }
  // }, [isOpen]);

  return (
    <div className="flex w-full justify-center items-center">
      <div className="relative w-full" ref={dropdownRef}>
        <div
          className="relative"
          onClick={() => {
            if (!disabled) {
              setIsOpen(!isOpen);
            }
          }}
        >
          <input
            type="text"
            autoComplete="new-password"
            className="input input-bordered input-sm cursor-pointer truncate w-full"
            value={value}
            placeholder={`Select ${label}`}
            readOnly
            disabled={disabled}
          />
          {errorMessage && ( // Conditionally render error message
            <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
          )}
          <span
            className={`absolute right-3 top-1/2 transform -translate-y-1/2 transition-transform ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          >
            <FontAwesomeIcon icon="chevron-down" />
          </span>
          {isOpen ? (
            <div
              className="absolute w-full z-50 rounded-box shadow-2xl"
              style={{
                top: shouldRenderAbove ? "auto" : "100%",
                bottom: shouldRenderAbove ? "100%" : "auto",
                maxHeight: "200px",
                overflow: "auto",
              }}
            >
              <ul className="menu bg-white shadow-2xl rounded-box w-full">
                {options.map((option) => (
                  <li key={option}>
                    <a
                      className={`block px-4 py-2 whitespace-normal break-words leading-normal ${
                        option === value
                          ? "bg-[#6F23FF] text-white"
                          : "text-gray-900"
                      }`}
                      onClick={() => handleOptionClick(option)}
                    >
                      {option}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
