// Necessary if using App Router to ensure this file runs on the client
"use client";

import { datadogRum } from "@datadog/browser-rum";

// Only initialize Datadog if not in local development environment
const shouldInitializeDatadog = () => {
  // Force disable via localStorage (you can set this in browser console with: localStorage.setItem('disableDatadog', 'true'))
  if (typeof window !== 'undefined' && window.localStorage.getItem('disableDatadog') === 'true') {
    return false;
  }
  
  // Check if we're in a local environment
  const isLocalEnvironment = 
    process.env.NODE_ENV === 'development' || 
    process.env.NEXT_PUBLIC_DATADOG_ENV === 'local' ||
    (typeof window !== 'undefined' && (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1' ||
      window.location.href.includes('localhost') ||
      window.location.href.includes('127.0.0.1')
    ));
  
  return !isLocalEnvironment;
};

// Add this to browser console to disable Datadog: localStorage.setItem('disableDatadog', 'true'); window.location.reload();
if (typeof window !== 'undefined' && shouldInitializeDatadog()) {
  datadogRum.init({
    applicationId: "b1849612-c6a7-4d2f-a91e-d98f4637869b",
    clientToken: "pub4a4b124e3aee61a29d30ced95bd87873",
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: "datadoghq.com",
    service: "socialcrowd-fe-vercel",
    env: process.env.NEXT_PUBLIC_DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      {
        match: process.env.NEXT_PUBLIC_CUSTOMER_API_HOST || "",
        propagatorTypes: ["tracecontext"],
      },
    ],
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
