"use client";
import React from "react";

type TextFieldProps = {
  value: string | number;
  onChange: any;
  disabled?: boolean;
  placeholder?: string;
  errorMessage?: string;
  type?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  className?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  maxLength?: number;
};

const TextField: React.FC<TextFieldProps> = ({
  value,
  onChange,
  disabled,
  placeholder,
  errorMessage,
  type,
  onKeyDown,
  onBlur,
  className,
  autoFocus,
  autoComplete,
  maxLength,
}) => {
  const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    if (type === "number") {
      e.currentTarget.blur();
    }
  };

  return (
    <>
      <input
        type={type || "text"}
        onKeyDown={onKeyDown}
        onWheel={handleWheel}
        className={`input input-bordered input-sm w-full ${className}`}
        value={value ?? ''}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={onBlur}
        autoFocus={autoFocus}
        autoComplete={autoComplete || "new-password"}
        data-lpignore="true"
        data-form-type="other"
        maxLength={maxLength}
      />
      {errorMessage && (
        <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
      )}
    </>
  );
};

export default TextField;
